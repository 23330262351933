import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Grid } from "@chakra-ui/react";
import { FC } from "react";
import { Image } from "src/components/atoms/Image";
import { Link } from "src/components/atoms/Link";
import { Txt } from "src/components/atoms/Txt";
import type { Color } from "src/lib/theme/colors";
import { Card, CardProps } from "../atoms/Card";

type ActionCardContentProps = CardProps & {
  name: string;
  imageUrl: string;
  width: number;
  height: number;
  textColor?: Color;
  isDisabled?: boolean;
};

export const ActionCardContent: FC<ActionCardContentProps> = ({
  name,
  imageUrl,
  width,
  height,
  isDisabled,
  textColor = "white",
  ...cardProps
}) => (
  <Card p={6} gap={4} {...cardProps}>
    <Grid justifyItems="center">
      <Image width={width} height={height} src={imageUrl} alt="ActionCard" />
    </Grid>

    <Txt textStyle="h4" color={textColor}>
      {name}
      {!isDisabled && <ChevronRightIcon fontSize="3xl" color="lightGray" />}
    </Txt>
  </Card>
);

type ActionCardProps = ActionCardContentProps & {
  href: string;
  isDisabled?: boolean;
};

export const ActionCard: FC<ActionCardProps> = ({
  href,
  gridColumn,
  isDisabled = false,
  ...actionCardContentProps
}) => {
  if (isDisabled)
    return (
      <Box gridColumn={gridColumn} w="100%" cursor="not-allowed">
        <ActionCardContent
          isDisabled={isDisabled}
          {...actionCardContentProps}
        />
      </Box>
    );

  return (
    <Link href={href} gridColumn={gridColumn} w="100%">
      <ActionCardContent isDisabled={isDisabled} {...actionCardContentProps} />
    </Link>
  );
};
